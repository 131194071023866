import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import PropTypes from "prop-types"

import Headroom from "react-headroom"
import { ThemeToggler } from "gatsby-plugin-dark-mode"

class Header extends React.Component {
  render() {
    const { siteTitle } = this.props
    return (
      <header>
        <Headroom>
          <div className="navbar">
            <h1>
              <AniLink cover direction="down" bg="#8c61ff" to="/">
                <svg
                  width="512"
                  height="512"
                  viewBox="0 -160 512 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m409.597656 265.449219c-30.347656 0-46.011718 11.015625-59.828125 20.730469-12.136719 8.53125-21.722656 15.269531-42.578125 15.269531-20.851562 0-30.4375-6.738281-42.570312-15.269531-13.816406-9.71875-29.480469-20.730469-59.828125-20.730469-30.34375 0-46.007813 11.015625-59.824219 20.730469-12.132812 8.53125-21.71875 15.269531-42.570312 15.269531-20.851563 0-30.4375-6.738281-42.570313-15.269531-13.820313-9.714844-29.484375-20.730469-59.828125-20.730469v30c20.855469 0 30.4375 6.738281 42.570312 15.273437 13.820313 9.714844 29.480469 20.726563 59.828126 20.726563 30.34375 0 46.007812-11.011719 59.824218-20.726563 12.132813-8.535156 21.71875-15.273437 42.570313-15.273437 20.855469 0 30.4375 6.738281 42.574219 15.273437 13.816406 9.714844 29.480468 20.726563 59.824218 20.726563 30.347656 0 46.011719-11.011719 59.832032-20.726563 12.132812-8.53125 21.71875-15.273437 42.574218-15.273437 20.855469 0 30.4375 6.738281 42.574219 15.273437 13.816406 9.714844 29.480469 20.726563 59.828125 20.726563v-30c-20.855469 0-30.441406-6.738281-42.574219-15.269531-13.820312-9.714844-29.484375-20.730469-59.828125-20.730469zm0 0" />
                  <path d="m409.597656 88.484375c-30.347656 0-46.011718 11.011719-59.828125 20.726563-12.136719 8.53125-21.722656 15.273437-42.578125 15.273437-20.851562 0-30.4375-6.742187-42.570312-15.273437-13.816406-9.714844-29.480469-20.726563-59.828125-20.726563-30.34375 0-46.007813 11.011719-59.824219 20.726563-12.132812 8.53125-21.71875 15.273437-42.570312 15.273437-20.851563 0-30.4375-6.742187-42.570313-15.273437-13.820313-9.714844-29.484375-20.726563-59.828125-20.726563v30c20.855469 0 30.4375 6.738281 42.570312 15.269531 13.820313 9.71875 29.480469 20.730469 59.828126 20.730469 30.34375 0 46.007812-11.015625 59.824218-20.730469 12.132813-8.53125 21.71875-15.269531 42.570313-15.269531 20.855469 0 30.4375 6.738281 42.574219 15.269531 13.816406 9.71875 29.480468 20.730469 59.824218 20.730469 30.347656 0 46.011719-11.015625 59.832032-20.730469 12.132812-8.53125 21.71875-15.269531 42.574218-15.269531 20.855469 0 30.4375 6.738281 42.574219 15.269531 13.816406 9.71875 29.480469 20.730469 59.828125 20.730469v-30c-20.855469 0-30.441406-6.742187-42.574219-15.273437-13.820312-9.714844-29.484375-20.726563-59.828125-20.726563zm0 0" />
                  <path d="m409.597656 176.96875c-30.347656 0-46.011718 11.011719-59.828125 20.726562-12.136719 8.53125-21.722656 15.273438-42.578125 15.273438-20.851562 0-30.4375-6.742188-42.570312-15.273438-13.816406-9.714843-29.480469-20.726562-59.828125-20.726562-30.34375 0-46.007813 11.011719-59.824219 20.726562-12.132812 8.53125-21.71875 15.273438-42.570312 15.273438-20.851563 0-30.4375-6.742188-42.570313-15.273438-13.820313-9.714843-29.484375-20.726562-59.828125-20.726562v30c20.855469 0 30.4375 6.738281 42.570312 15.269531 13.820313 9.714844 29.480469 20.730469 59.828126 20.730469 30.34375 0 46.007812-11.015625 59.824218-20.730469 12.132813-8.53125 21.71875-15.269531 42.570313-15.269531 20.855469 0 30.4375 6.738281 42.574219 15.269531 13.816406 9.714844 29.480468 20.730469 59.824218 20.730469 30.347656 0 46.011719-11.015625 59.832032-20.730469 12.132812-8.53125 21.71875-15.269531 42.574218-15.269531 20.855469 0 30.4375 6.738281 42.574219 15.269531 13.816406 9.714844 29.480469 20.730469 59.828125 20.730469v-30c-20.855469 0-30.441406-6.742188-42.574219-15.273438-13.820312-9.714843-29.484375-20.726562-59.828125-20.726562zm0 0" />
                  <path d="m469.425781 20.730469c-13.820312-9.71875-29.484375-20.730469-59.828125-20.730469-30.347656 0-46.011718 11.011719-59.828125 20.730469-12.136719 8.53125-21.722656 15.269531-42.578125 15.269531-20.851562 0-30.4375-6.738281-42.570312-15.269531-13.816406-9.71875-29.480469-20.730469-59.828125-20.730469-30.34375 0-46.007813 11.011719-59.824219 20.730469-12.132812 8.53125-21.71875 15.269531-42.570312 15.269531-20.851563 0-30.4375-6.738281-42.570313-15.269531-13.820313-9.71875-29.484375-20.730469-59.828125-20.730469v30c20.855469 0 30.4375 6.738281 42.570312 15.269531 13.820313 9.71875 29.480469 20.730469 59.828126 20.730469 30.34375 0 46.007812-11.011719 59.824218-20.730469 12.132813-8.53125 21.71875-15.269531 42.570313-15.269531 20.855469 0 30.4375 6.738281 42.574219 15.269531 13.816406 9.71875 29.480468 20.730469 59.824218 20.730469 30.347656 0 46.011719-11.011719 59.832032-20.730469 12.132812-8.53125 21.71875-15.269531 42.574218-15.269531 20.855469 0 30.4375 6.738281 42.574219 15.269531 13.816406 9.71875 29.480469 20.730469 59.828125 20.730469v-30c-20.855469 0-30.441406-6.738281-42.574219-15.269531zm0 0" />
                </svg>
                {siteTitle}
              </AniLink>
            </h1>

            <ThemeToggler>
              {({ theme, toggleTheme }) => (
                <label className="tog">
                  <input
                    type="checkbox"
                    onChange={e =>
                      toggleTheme(e.target.checked ? "dark" : "light")
                    }
                    checked={theme === "dark"}
                  />
                  {theme === "dark" ? (
                    <div className="on"></div>
                  ) : (
                    <div className="off"></div>
                  )}
                </label>
              )}
            </ThemeToggler>
          </div>
        </Headroom>
      </header>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

export default Header
